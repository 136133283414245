import { render, staticRenderFns } from "./balanceRecord.vue?vue&type=template&id=709e5424&scoped=true&"
import script from "./balanceRecord.vue?vue&type=script&lang=js&"
export * from "./balanceRecord.vue?vue&type=script&lang=js&"
import style0 from "./balanceRecord.vue?vue&type=style&index=0&id=709e5424&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "709e5424",
  null
  
)

export default component.exports