<template>
  <div class="balanceRecord" :style="isWeixin_status ? '':'padding-top:50px'">
    <NavBar v-if="!isWeixin_status" fixed title="余额变更记录" @click-left="goBack" left-arrow></NavBar>
    <List v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <Cell
        v-for="(item, idx) in listArr"
        :key="idx"
        :title="item.text"
        :value="item.type==1?`+${item.amount}元`:`-${item.amount}元`"
        :label="item.create_time"
      ></Cell>
    </List>
    <Empty v-if='listArr.length === 0' description="暂无数据"></Empty>
  </div>
</template>
<script>
import { NavBar, List, Cell, Empty} from "vant";
import UserInfo from "@/api/user";
export default {
  components: {
    NavBar,
    List,
    Cell,
    Empty
  },
  data() {
    return {
      loading: false,
      finished: false,
      listArr: [],
      show: false,
      details: {},
      page: 0
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async onLoad() {
      this.page++;
      try {
        const res = await UserInfo.balanceLogs(this.page);
        if (res.code == 200) {
          if (res.data.length > 0) {
            res.data.forEach(item => {
              this.listArr.push(item);
            });
            this.loading = false;
            if (res.total == this.listArr.length) {
              this.finished = true;
            }
          } else {
            throw res.msg;
          }
        } else {
          this.loading = false;
          this.finished = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async checkDetail(id) {
      console.log(id);
      this.show = true;
      try {
        let res = await UserInfo.balanceOne(id);
        this.details = res.data;
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.balanceRecord {
  width: 100%;
  height: 100vh;
  background: rgba(244, 244, 244, 1);
  overflow-y: scroll;
  overflow-x: hidden;
  .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .centerBox {
      width: 300px;
      height: 400px;
      background: #fff;
      .header {
        font-weight: 500;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 20px;
        border-bottom: 1px solid rgb(182, 179, 179);
      }
      .row {
        width: 100%;
        height: 30px;
        display: flex;
        flex-direction: row;
        .col {
          width: 50%;
          height: 100%;
        }
      }
    }
  }
}
</style>
